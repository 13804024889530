import React from "react";
import Navbar from "../../utils/navbar/navbar";
import { Container, TutorialsH1 } from "./styles";
import { motion } from "framer-motion";
import TutorialCard from "../../components/tutorial-card/tutorial-card";
import dsalogo from "../../assets/dsalogo.png";
import reactlogo from "../../assets/reactlogo.png";
import jsıconlogo from "../../assets/jsıconlogo.png";
import jobhiringlogo from "../../assets/jobhiringlogo.png";
import learninglogo from "../../assets/learninglogo.png";
import dsaphilsopylogo from "../../assets/dsaphilsopylogo.png";
import startlearninglogo from "../../assets/startlearninglogo.png";

const TutorialsPage = () => {
  return (
    <Container>
      <Navbar />
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <TutorialsH1>Ufkunuzu genişletin.</TutorialsH1>
      </motion.div>
      <TutorialCard
        imgSrc={dsalogo}
        title="Veri Yapıları Serisi"
        author="Oral Berk Arslan"
        date="Nisan 17, 2024"
        description="Bu oynatma listesinde, temel veri yapılarını ve algoritmaları adım adım öğreniyoruz."
        link="https://www.youtube.com/watch?v=m-LVtwGr6SQ&list=PLwd7krRowB0qXfq4L_A6AsMm3J6r1jxYv"
        type="video"
      />
      <TutorialCard
        imgSrc={reactlogo}
        title="React - Redux Toolkit İle Kimlik Doğrulama (Authentication) İşlemleri"
        author="Oral Berk Arslan"
        date="Mart 30, 2024"
        description="Bu videoda, React ve Redux Toolkit kullanarak kimlik doğrulama işlemlerini nasıl gerçekleştirebileceğimizi inceliyoruz. Adım adım kullanıcı kaydı, giriş ve oturum yönetimi gibi temel işlemleri ele alıyoruz."
        link="https://youtu.be/ZxBZUXpljMk?si=wcgDTxg6zJfXXbrb"
        type="video"
      />
      <TutorialCard
        imgSrc={learninglogo}
        title="Öğrenme Kavramı Üzerine 3 Prensip"
        author="Oral Berk Arslan"
        date="Mart 5, 2024"
        description="Bu yazıda, etkili öğrenmenin temel prensiplerini keşfedeceksiniz. Öğrenme sürecinizi daha verimli ve keyifli hale getirecek üç temel ilkeye odaklanıyoruz."
        link="https://www.jazzforcows.com/ogrenmekavramiuzerine3prensip"
        type="blog"
      />
      <TutorialCard
        imgSrc={jobhiringlogo}
        title="Tecrübesiz Bir Bilgisayar Mühendisi Olarak Nasıl İş veya Staj Bulunur?"
        author="Oral Berk Arslan"
        date="Şubat 24, 2024"
        description="Bu videoda, deneyimsiz bir bilgisayar mühendisi olarak iş veya staj bulma süreci için deneyimlerimden bahsediyorum."
        link="https://www.youtube.com/watch?v=3NEepVAt3jw&t=163s"
        type="video"
      />
      <TutorialCard
        imgSrc={startlearninglogo}
        title="Yazılım Öğrenmeye Hangi Dil ile Başlamalıyım?"
        author="Oral Berk Arslan"
        date="Ocak 21, 2024"
        description="Yazılım dünyasına adım atmak isteyenler için en uygun programlama dilini seçme rehberi. Popüler dillerin avantajlarını ve dezavantajlarını inceleyerek, sizin için en doğru tercihi yapmanıza yardımcı oluyorum."
        link="https://www.jazzforcows.com/yazilim%C3%B6grenmeyehangidillebaslamaliyim"
        type="blog"
      />

      <TutorialCard
        imgSrc={dsaphilsopylogo}
        title="Veri Yapıları ve Felsefe"
        author="Oral Berk Arslan"
        date="Kasım 30, 2023"
        description="Bu makalede, veri yapılarını felsefi bir bakış açısıyla ele alıyoruz. Veri yapılarına dair temel kavramları ve bu yapıların bilgisayar bilimindeki önemini, felsefi düşüncelerle harmanlayarak anlatıyorum."
        link="https://www.jazzforcows.com/veriyapilarivefelsefe"
        type="blog"
      />

      <TutorialCard
        imgSrc={jsıconlogo}
        title="JavaScript'teki 'this' keywordü (Türkçe)"
        author="Oral Berk Arslan"
        date="Ekim 17, 2023"
        description="Bu videoda, JavaScript'teki this keywordünü detaylı bir şekilde inceliyoruz. Farklı kullanım senaryoları ve 'this' keywordünün nasıl çalıştığını anlamanızı sağlayacak örnekler çözüyoruz."
        link="https://youtu.be/CFYsmbKtFUQ?si=vS_SAErcVkxQbhHK"
        type="video"
      />
    </Container>
  );
};

export default TutorialsPage;
