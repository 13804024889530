import React from "react";
import Navbar from "../../utils/navbar/navbar";
import Footer from "../../utils/footer/footer";
import { Link } from "react-router-dom"; // React Router kullanıyorsanız

import {
  Container,
  HeroSection,
  ContentSection,
  GeneralInfoContainer,
  InfoContainer,
  InfoLogoImg,
  CustomDiv,
  InfoLogoH2,
  InfoLogoH3,
  ContentImgGrid,
  GridImg,
  GoalsSection,
  GoalsH1,
  GoalsH2,
  GoalsGeneralContainer,
  GoalsContainer,
  GoalsInfoContainer,
  GoalsP,
  Icon,
  FollowSection,
  FollowH1,
  IconContainer,
  IconLink,
  InstructorSection,
  InstructorH1,
  InstructorH2,
  CardContainer,
  ProfileImage,
  Name,
  Title,
} from "./styles";
import SubscriptionForm from "../../components/subscription-form/subscription-form";
import infologo1 from "../../assets/ınfologo1.png";
import infologo2 from "../../assets/ınfologo2.png";
import infologo3 from "../../assets/ınfologo3.png";
import jslogo from "../../assets/jslogo.png";
import javalogo from "../../assets/javalogo.png";
import dblogo from "../../assets/dblogo.png";
import cvlogo from "../../assets/cvlogo.png";
import profil from "../../assets/Profil.JPG";

import {
  FaGithub,
  FaLinkedin,
  FaYoutube,
  FaRss,
  FaInstagram,
  FaBlog,
} from "react-icons/fa";

import { motion } from "framer-motion";

const HomePage = () => {
  return (
    <Container>
      <Navbar />
      <HeroSection>
        <SubscriptionForm />
        <GeneralInfoContainer>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <InfoContainer>
              <InfoLogoImg src={infologo1}></InfoLogoImg>
              <CustomDiv>
                <InfoLogoH2>Eğitici Videolar</InfoLogoH2>
                <InfoLogoH3>
                  Teknoloji becerilerinizi ve bilginizi artırmak için
                  hazırladığım geniş eğitici video koleksiyonumu keşfedin.
                </InfoLogoH3>
              </CustomDiv>
            </InfoContainer>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            <InfoContainer>
              <InfoLogoImg src={infologo2}></InfoLogoImg>
              <CustomDiv>
                <InfoLogoH2>Blog Yazıları</InfoLogoH2>
                <InfoLogoH3>
                  Teknolojiye dair bakış açınızı değiştiren blog yazılarımla
                  bilgi ufkunuzu genişletin.
                </InfoLogoH3>
              </CustomDiv>
            </InfoContainer>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9 }}
          >
            <InfoContainer>
              <InfoLogoImg src={infologo3}></InfoLogoImg>
              <CustomDiv>
                <InfoLogoH2>Rehberlik</InfoLogoH2>
                <InfoLogoH3>
                  İşe yarayacak roadmap'ler ve pratik tavsiyelerle teknoloji
                  yolculuğunuzu kolaylaştırın.
                </InfoLogoH3>
              </CustomDiv>
            </InfoContainer>
          </motion.div>
        </GeneralInfoContainer>
      </HeroSection>

      <ContentSection>
        <InfoLogoH2>Hedefleriniz için ilham veren içerikler</InfoLogoH2>
        <InfoLogoH3>
          Frontend | Backend | Database | Kariyer Tavsiyeleri
        </InfoLogoH3>
        <ContentImgGrid>
          <GridImg src={jslogo} alt="Logo 1" />
          <GridImg src={javalogo} alt="Logo 2" />
          <GridImg src={dblogo} alt="Logo 3" />
          <GridImg src={cvlogo} alt="Logo 4" />
        </ContentImgGrid>
        <InfoLogoH3>Ve fazlası!</InfoLogoH3>
      </ContentSection>

      <GoalsSection>
        <GoalsH1>
          Hedeflerinize <span style={{ color: "#e697d2" }}>ulaşmanız</span> için
          gereken her şey <span style={{ color: "#f7d066" }}>burada</span>
        </GoalsH1>
        <GoalsH2>
          Size mümkün olan en iyi öğrenme deneyimini sunmak için.
        </GoalsH2>

        <GoalsGeneralContainer>
          <GoalsContainer>
            <GoalsInfoContainer>
              <Icon>▶️</Icon>
              <GoalsH2>Teknik Videolar</GoalsH2>
              <GoalsP>
                En güncel yazılım ve teknoloji trendlerini öğrenmek için teknik
                videoları izleyin.
              </GoalsP>
            </GoalsInfoContainer>
            <GoalsInfoContainer>
              <Icon>📝</Icon>
              <GoalsH2>Blog Yazıları</GoalsH2>
              <GoalsP>
                Yazılım mühendisliğinden kişisel gelişime kadar pek çok konuda
                ufuk açan blog yazılarını keşfedin.
              </GoalsP>
            </GoalsInfoContainer>
            <GoalsInfoContainer>
              <Icon>💡</Icon>
              <GoalsH2>Pratik Tavsiyeler</GoalsH2>
              <GoalsP>
                Günlük yaşamda ve iş hayatında işinize yarayacak pratik
                tavsiyelerle başarıya ulaşın.
              </GoalsP>
            </GoalsInfoContainer>
          </GoalsContainer>
        </GoalsGeneralContainer>
      </GoalsSection>

      <FollowSection>
        <FollowH1>
          Beni <span style={{ color: "#e697d2" }}> sosyal </span>{" "}
          kanallarımdan
          <span style={{ color: "#f7d066" }}> takip </span> edin
        </FollowH1>
        <IconContainer>
          <IconLink
            href="https://www.linkedin.com/in/oralberkarslan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin size={50} />
          </IconLink>

          <IconLink
            href="https://www.youtube.com/channel/UCoiJVd4AaYhW2BC-O8_7krA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube size={50} />
          </IconLink>
          <IconLink
            href="https://www.jazzforcows.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaBlog size={50} />
          </IconLink>
          <IconLink
            href="https://github.com/berkarslann"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub size={50} />
          </IconLink>
          <IconLink
            href="https://www.instagram.com/berk.arsln"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size={50} />
          </IconLink>
        </IconContainer>
      </FollowSection>

      <InstructorSection>
        <InstructorH1>Yol Arkadaşınızla Tanışın</InstructorH1>
        <InstructorH2>
          Tüm içerikler aşağıdaki kişi tarafından üretiliyor. Çünkü yaptığı işi
          seviyor.
        </InstructorH2>
        <CardContainer>
          <ProfileImage
            src={profil}
            alt="Maximilian Schwarzmüller"
          />
          <Name>Oral Berk Arslan</Name>
          <Title>Software Engineer</Title>
        </CardContainer>
      </InstructorSection>

    </Container>
  );
};

export default HomePage;
