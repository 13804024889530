import React from "react";
import { Container, Img, H1, SSubButton } from "./styles.jsx";
import logo from "../../assets/logo.png";
import { Link, useLocation } from "react-router-dom"; // useLocation'u ekleyin

const Navbar = () => {
  const location = useLocation();

  return (
    <Container>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Img src={logo}></Img>
      </Link>
      <Link to='/tutorials' style={{ textDecoration: 'none' }}>
        <H1 style={{ color: location.pathname === '/tutorials' ? '#9d7af7' : 'white' }}>İçerikler</H1>
      </Link>
      {/* <Link to='/community' style={{ textDecoration: 'none' }}>
        <H1 style={{ color: location.pathname === '/community' ? '#9d7af7' : 'white' }}>Komunite</H1>
      </Link> */}
      <SSubButton onClick={() => window.open('https://banabicoffee.com/@berk', '_blank')}>Bana Kahve Ismarlayın</SSubButton>

    </Container>
  );
};

export default Navbar;
