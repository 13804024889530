import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  font-family: ${(props) => props.theme.fontFamily};
  position: absolute; 
  padding-top:1rem;
  top: 1rem;
  width: 100%; /* Container genişliği */
  gap: 2rem; /* Öğeler arasındaki boşluk */
`;
export const Img = styled.img`
width:150px;
@media (max-width: 768px) {
  width: 150px; /* Küçük ekranlar için genişlik */
}

@media (max-width: 480px) {
  width: 100px; /* Daha küçük ekranlar için genişlik */
}
`
export const SSubButton = styled.button`
  background-color: #9d7af7;
  border: none;
  border-radius:0.2rem;
  width: 10%;
  height:2rem;
  transition: background-color 0.3s ease, border 0.3s ease;
  font-family: ${(props) => props.theme.fontFamily};
  &:hover {
    background-color: #b39dfa; 
    border: 2px solid #9d7af7; 
    cursor:pointer
  }
  @media (max-width: 768px) {
    width: 150px; /* Küçük ekranlar için genişlik */
  }
  
  @media (max-width: 480px) {
    width: 100px; /* Daha küçük ekranlar için genişlik */
    color:#0e0322;
  }
`;
export const H1 = styled.h1`
color:white;
font-size:1rem;
`