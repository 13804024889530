import React from 'react';
import styled from 'styled-components';

export const FooterContainer = styled.footer`
  background-color: #2C2C2C;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const FooterText = styled.p`
  margin: 0;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>© 2024 Your Company. All rights reserved.</FooterText>
    </FooterContainer>
  );
};

export default Footer;
