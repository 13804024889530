import React from "react";
import styled from "styled-components";

const TutorialCard = ({ imgSrc, title, author, date, description, link, type }) => {
  const typeText = type === "video" ? "🎥 Video" : "✍️ Blog Yazısı";
  
  return (
    <Card onClick={() => window.open(link, "_blank")}>
      <ImageSection>
        <img src={imgSrc} alt={title} />
      </ImageSection>
      <ContentSection>
        <Title>{title}</Title>
        <Meta>
          <Author>{author}</Author>
          <Date>📅 {date}</Date>
          <Type>{typeText}</Type>
        </Meta>
        <Description>{description}</Description>
      </ContentSection>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 50rem;
  margin: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    flex-direction: row;
    height: auto;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  img {
    max-width: 100%;
    border-radius: 5px;
    @media (min-width: 768px) {
      max-width: 80%;
    }
  }
`;

const ContentSection = styled.div`
  flex: 2;
  padding: 20px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  color: #f7b731;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Meta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const Author = styled.span`
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

const Date = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const Type = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0;
  margin-top: 5px;

  @media (min-width: 768px) {
    margin-left: 20px;
    margin-top: 0;
  }
`;

const Description = styled.p`
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export default TutorialCard;
