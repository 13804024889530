import React from "react";
import Navbar from "../../utils/navbar/navbar";

import { Link } from "react-router-dom"; 

import {
  Container,
  HeroSection,
  ResumeImg
} from "./styles";
import ResumeForm from "../../components/resume-form/resume-form";
import resume from '../../assets/resume.png'


import { motion } from "framer-motion";

const ResumePage = () => {
  return (
    <Container>
      <Navbar />
      <HeroSection>
        <ResumeForm/>
        <ResumeImg src={resume}></ResumeImg>
      </HeroSection>

     

    </Container>
  );
};

export default ResumePage;
