import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.fontFamily};
  width: 100%;
  min-height: 100vh; /* Min height kullanarak içeriğin sığmasını sağlayın */
  box-sizing: border-box; /* Box-sizing ile padding ve border'ı dahil edin */
  padding: 3rem; /* Gerektiğinde padding ekleyin */
  overflow: hidden; /* Taşan içeriği gizlemek için overflow ayarı */

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto; /* Küçük ekranlarda otomatik yükseklik */
    padding: 1rem; /* Mobilde daha az padding */
  }
`;

export const HeroSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-top: 5rem;
  @media (max-width: 768px) {
    margin-top: 0rem;
    flex-direction: column;
  }
`;

export const ContentSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-top: 10rem;
  @media (max-width: 768px) {
    margin-top: 2rem; /* Mobilde daha az margin */
    flex-direction: column;
  }
`;

export const GoalsSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-top: 10rem;
  background-color: rgb(27, 10, 45);
  border: none;
  box-shadow: 0 0 10px 5px rgba(27, 10, 45, 0.3),
    0 0 20px 10px rgba(27, 10, 45, 0.4), 0 0 30px 15px rgba(27, 10, 45, 0.5),
    0 0 40px 20px rgba(27, 10, 45, 0.6), 0 0 50px 25px rgba(27, 10, 45, 0.7),
    0 0 60px 30px rgba(27, 10, 45, 0.8); /* Gölgeyi merkezde tutma */
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const GeneralInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }

  h1 {
    color: white;
  }
`;

export const InfoLogoImg = styled.img`
  width: 10%;
  margin-right: 1rem;

  @media (max-width: 768px) {
    width: 20%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

export const CustomDiv = styled.div`
  width: 35rem;

  @media (max-width: 768px) {
    width: auto;
  }
`;

export const InfoLogoH2 = styled.h2`
  color: #e697d2;
  font-size: 1.2rem;
  margin: 1px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const InfoLogoH3 = styled.h3`
  color: #c3bdd8;
  margin: 0px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const ContentH1 = styled.h1`
  color: #e697d2;
  font-size: 1.2rem;
  margin: 1px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const ContentImgGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 1rem;
  justify-items: center;
  align-items: center;
  margin-top: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Mobilde 2 sütun */
  }
`;

export const GridImg = styled.img`
  width: 100%;
  max-width: 100px; /* Logoların maksimum genişliği */
  height: auto;
  margin: 2rem;

  @media (max-width: 768px) {
    margin: 1rem; /* Mobilde daha az margin */
  }
`;

export const GoalsH1 = styled.h1`
  color: #c3bdd8;
  font-size: 1.4rem;
  margin: 1px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const FollowH1 = styled.h1`
  color: #c3bdd8;
  font-size: 2.3rem;
  margin-top: 10rem;

  @media (max-width: 768px) {
    font-size: 1.3rem; /* Mobilde daha küçük font boyutu */
    margin-top: 3rem; /* Mobilde daha az margin */
  }
`;

export const GoalsH2 = styled.h2`
  color: #c3bdd8;
  font-size: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const GoalsGeneralContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const GoalsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const GoalsInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
`;

export const GoalsP = styled.p`
  color: #b0b0b5;
  flex-direction: column;
  margin-top: 0rem;
`;

export const Icon = styled.div`
  color: #ff5c8d;
  font-size: 50px;
`;

export const FollowSection = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;

`;

export const InstructorSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 12rem;

  @media (max-width: 768px) {
    margin-top: 6rem; /* Mobilde daha az margin */
  }
`;

export const InstructorH1 = styled.h1`
  color: #c3bdd8;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Mobilde daha küçük font boyutu */
  }
`;

export const InstructorH2 = styled.h2`
  color: #c3bdd8;
  margin-top: 0px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;

  @media (max-width: 768px) {
    gap: 20px; /* Mobilde daha az boşluk */
  }
`;

export const IconLink = styled.a`
  color: white;
  font-size: 1.5rem; /* Varsayılan boyut */

  &:hover {
    color: #6c3fa6;
  }

  @media (max-width: 768px) {
    font-size: 1rem; /* Mobilde daha küçük boyut */
  }
`;


export const CardContainer = styled.div`
  background-color: #2c2c2c;
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 40rem;
  max-width: 400px;
  text-align: center;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    width: auto;
    padding: 10px; /* Mobilde daha az padding */
  }
`;

export const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100px; /* Mobilde daha küçük boyut */
    height: 100px;
  }
`;

export const Name = styled.h2`
  margin: 10px 0;
`;

export const Title = styled.h3`
  color: #cccccc;
  margin: 5px 0;
`;
