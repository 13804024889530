import React from 'react';
import styled from 'styled-components';

const IframeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  height: 500px;
  margin-right: 2rem;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    overflow: hidden;
  }

  @media (max-width: 600px) {
    iframe {
      height: 420px; // Küçük ekranlarda da yüksekliği sabit tutar
      margin: 0;

    }
  }
`;

const SubscriptionForm = () => {
  return (
    <IframeWrapper>
      <iframe
        src="https://90481891.sibforms.com/serve/MUIFAKx2xzkVH3XYRWndPnH_-hT_zr2ml0fyzLMkUj9H3MLUtuW___wFgEjFXDHJbc_M2jUMFU6ptSRoWBky2plUVlBILZdaRr_fdl4ZprH3n9gnz8Of1joraGwxOuW8qeoMM7BuJsYHtMF6-P9XFF0nu9ISluoO1Cny5sU8lXF4JpQIKmTieV-SyL_XGphObCpv2KHayS3wb8gP"
        scrolling="no"
        allowFullScreen
      ></iframe>
    </IframeWrapper>
  );
};

export default SubscriptionForm;
