import React from 'react';
import styled from 'styled-components';

const IframeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  height: 500px;
  margin-right: 2rem;
  width:40rem;  
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    overflow: hidden;
  }

  @media (max-width: 600px) {
    iframe {
      height: 430px; // Küçük ekranlarda da yüksekliği sabit tutar
      margin: 0;
      width:28rem; 

    }
  }
`;

const ResumeForm = () => {
  return (
    <IframeWrapper>
      <iframe
        src="https://90481891.sibforms.com/serve/MUIFAA3TtJfbrF304IdRf1P8gDCZsH70jSNTjR1SghJ67VIC2AsWCCpYEe0yBeozjRB0bBFhlsu0V5VfrKhBxAJxSSuFa6YVqroJEvT72R7ittcIK-8uoRPoLl3_iisBxoWINtQSgvp8pIO0XSRCu9zaNAipT2R0BcLEYgoo_el8pYyDPCMjcCzAZK1UbIvGbt4diHTLzK8e74Kn"
        scrolling="no"
        allowFullScreen
      ></iframe>
    </IframeWrapper>
  );
};

export default ResumeForm;
