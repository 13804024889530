import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.fontFamily};
  width: 100%;
  min-height: 100vh; /* Min height kullanarak içeriğin sığmasını sağlayın */
  box-sizing: border-box; /* Box-sizing ile padding ve border'ı dahil edin */
  padding: 3rem; /* Gerektiğinde padding ekleyin */
  overflow: hidden; /* Taşan içeriği gizlemek için overflow ayarı */

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto; /* Küçük ekranlarda otomatik yükseklik */
  }
`;
export const TutorialsH1 = styled.h1`
  color: #c3bdd8;
  font-size: 2.3rem;
margin-top:7rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
export const TutorialsH2 = styled.h2`
color: #c3bdd8;
font-size: 2.3rem;
margin-bottom:0px;
@media (max-width: 768px) {
  font-size: 1rem;
}
`