import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import HomePage from "../pages/home/HomePage";
import ResumePage from "../pages/resume/ResumePage";
import TutorialsPage from "../pages/tutorials/TutorialsPage";

const AppRoutes = () => {
  useEffect(() => {
    if (window.location.protocol !== 'https:') {
      window.location.href = 'https://' + window.location.hostname + window.location.pathname + window.location.search;
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/tutorials" element={<TutorialsPage />} />
        <Route path="/resume" element={<ResumePage />} />

      </Routes>
    </Router>
  );
};

export default AppRoutes;
