import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.fontFamily};
  width: 100%;
  min-height: 100vh; /* Min height kullanarak içeriğin sığmasını sağlayın */
  box-sizing: border-box; /* Box-sizing ile padding ve border'ı dahil edin */
  padding: 3rem; /* Gerektiğinde padding ekleyin */
  overflow: hidden; /* Taşan içeriği gizlemek için overflow ayarı */

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto; /* Küçük ekranlarda otomatik yükseklik */
    padding: 1rem; /* Mobilde daha az padding */
  }
`;

export const HeroSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-top: 5rem;
  @media (max-width: 768px) {
    margin-top: 0rem;
    flex-direction: column;
  }
`;

export const ResumeImg = styled.img`
  width: 100%;
  max-width: 400px; /* Maksimum genişlik */
  height: auto; /* Yüksekliği genişliğe göre otomatik ayarlar */
  display: block; /* Resmin etrafında boşluk bırakmamak için */
  margin: 0 auto; /* Ortalamak için */
  
  @media (max-width: 768px) {
    max-width: 300px; /* Küçük ekranlarda maksimum genişlik */
  }

  @media (max-width: 480px) {
    max-width: 200px; /* Daha küçük ekranlarda maksimum genişlik */
  }
`;